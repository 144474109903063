<form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-9 col-md-8">
          <h2 class="card-title">Search Criteria</h2>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <!-- Brand Field -->
        <div class="col-xs-4 col-sm-4 col-lg-4">
          <div class="form-group">
            <label for="brand-input">Brand</label>
            <span class="mandatory-field-symbol"> *</span>
            <ng-option [value]=""></ng-option>

            <ng-select
              formControlName="brandCode"
              placeholder="Select a Brand"
              [compareWith]="compareBrandFn"
              [searchFn]="customBrandSearch"
              (change)="getAssociatedPropList()"
            >
              <ng-option *ngFor="let eachBrand of brandsList" [value]="eachBrand">
                {{ eachBrand.brandName
                }}<span class="pid-number">
                  -  {{ eachBrand.brand }}</span
              ></ng-option
              >
            </ng-select>


          </div>
        </div>
        <!-- Property Field -->
        <div class="col-xs-4 col-sm-4 col-lg-4">
          <div class="form-group">
            <label for="property-input">Property</label>
            <span class="mandatory-field-symbol"> *</span>


            <ng-option [value]=""></ng-option>
            <ng-select
              formControlName="propertyId"
              placeholder="Select a Property"
              [compareWith]="comparePropertyFn"
              [searchFn]="customSearch"
            >
              <ng-option *ngFor="let eachProperty of propertyList" [value]="eachProperty">
                {{ eachProperty.propertyName
                }}<span class="pid-number">
                  -  {{ eachProperty.propertyId }}</span
              ></ng-option
              >
            </ng-select>
          </div>
        </div>

        <div class="col-xs-4 col-sm-4 col-lg-4">
          <div class="form-group">
            <label for="agency-input">Agency Name</label>
            <input id="agency-input"
                   type="text"
                   class="form-control"
                   formControlName="agency"
                   placeholder="Enter Agency Name">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-2 col-sm-2 col-lg-2">
          <div class="form-group">
            <label for="invoice-supply-date-from-input">
              Invoice Supply Date From
            </label>
            <div class="input-group input-group-datepicker">
              <input bsDatepicker style="border-color:#ccc;"
                     class="form-control datepicker-paddingleft"
                     ngDefaultControl
                     #dp1="bsDatepicker"
                     formControlName="invoiceSupplyDateFrom"
                     [bsConfig]="configuration"
                     tooltip=""/>
              <div class="input-group-append form-group">
                <button style="border-color:#ccc;" class="btn" type="button"
                        (click)="dp1.toggle()">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-2 col-sm-2 col-lg-2">
          <div class="form-group">
            <label for="invoice-supply-date-to-input">
              To
            </label>
            <div class="input-group input-group-datepicker">
              <input bsDatepicker style="border-color:#ccc;"
                     class="form-control datepicker-paddingleft"
                     ngDefaultControl
                     #dp2="bsDatepicker"
                     formControlName="invoiceSupplyDateTo"
                     [bsConfig]="configuration"
                     tooltip=""/>
              <div class="input-group-append form-group">
                <button style="border-color:#ccc;" class="btn" type="button"
                        (click)="dp2.toggle()">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-4 col-sm-4 col-lg-4">
          <div class="form-group">
            <label for="invoice-number-input">Invoice Number</label>
            <input id="invoice-number-input"
                   type="text"
                   class="form-control"
                   formControlName="invoiceNumber"
                   placeholder="Enter invoice number">
          </div>
        </div>
        <div class="col-xs-4 col-sm-4 col-lg-4 text-right align-content-center">
          <button *ngIf="isResetFilterButtonVisible()"
                  style="background-color: #ffffff;border: 1px solid  #9cafbe; margin-right: 9px"
                  class="btn btn-outline-primary search-button1" type="button" (click)="onReset()">
            <i style="color: #336eb4" class="far fa-times"></i>
            <span style="margin-left: 10px; color: #336eb4">Reset Filters </span>
          </button>

          <button style="background-color: #336eb4; border: 1px solid  #d7dbe2;"
                  class="btn btn-outline-primary search-button1"
                  type="submit"
                  [disabled]="!this.isFormValid()">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="card">-->
  <!-- <div class="card-body">-->


  <div class="card-section"  *ngIf="(invoices | async).pagination?.itemsPerPage > 0">
    <div class="card-section-body">
      <div class="card card-table">
        <div class="card-header">
          <h2 class="card-title">
            Invoices ({{
               (invoices | async).pagination?.totalNumberOfInvoicesForSearch }})
          </h2>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table"  *ngIf="(invoices | async).pagination?.itemsPerPage > 0">
              <thead>
              <tr>
                <th>Brand</th>
                <th>Hotel</th>
                <th>Agencies</th>
                <th>Invoice Supply Date</th>
                <th>Invoice Number</th>
                <th>Download PDF</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let invoice of (invoices | async).invoices">
                <td>{{  this.brandSelected }}</td>
                <td>{{ invoice.propertyName }}</td>
                <td>{{ invoice.agencies }}</td>
                <td>{{ invoice.invoiceSupplyDate }}</td>
                <td>{{ invoice.invoiceNumber }}</td>
                <td class="pdf-icon-cell" (click)="downloadPdf(invoice)">
                  <i class="fas fa-file-pdf"></i> PDF
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-footer" *ngIf="(invoices | async).pagination?.itemsPerPage > 0">
          <div class="form-inline float-right">
            <span class="d-none d-md-inline mr-3  pagination-text">Items Per Page</span>
            <select formControlName="limit" (change)="changeLimitPerPage()"
                    class="form-control mr-4"
                    aria-label="Items Per Page"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span class="d-none d-md-inline mr-3 pagination-text"> Viewing {{this.initialRecordInPage}}
              -{{this.finalRecordInPage}} of {{(invoices | async).pagination?.totalNumberOfInvoicesForSearch}} </span>

            <div class="btn-group" role="group" aria-label="Data Row Page">
              <button type="button" [disabled]="!isPreviousButtonEnabled" (click)="onPreviousClick($event)"
                      class="btn btn-secondary" aria-label="Previous">
                <i class="fas fa-caret-left"></i>
                <span class="d-none d-md-inline ml-2">Previous</span>
              </button>
              <button [disabled]="!isNextButtonEnabled" (click)="onNextClick($event)" class="btn btn-secondary"
                      aria-label="Next">
                <span class="d-none d-md-inline mr-2">Next</span>
                <i class="fas fa-caret-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-section"  *ngIf="(invoices | async).pagination?.itemsPerPage == 0">
    <div class="card-section-body">
      <p class="no-data-message">No Invoices Found</p>
    </div>
  </div>

</form>
