<!-- app.component.html -->

<div class="container-layout">
  <app-navbar-left></app-navbar-left>
  <div class="container-layout-right">
    <app-navbar-top></app-navbar-top>
    <router-outlet></router-outlet>
  </div>

</div>
<app-spinner-modal></app-spinner-modal>
<app-session-timeout
  redirectionUrl="/default-actions?action=logout"
  warningHeaderMessage="Session Disconnect Warning"
  warningBodyMessage="Your Session will expire automatically due to inactivity in: "
  timeoutWarningMessageDuration=60
  idleTimeoutDuration=1740>
</app-session-timeout>

