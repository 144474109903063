import { Component } from '@angular/core';
import {environment} from '@app-env/environment';

@Component({
  selector: 'app-navbar-left',
  templateUrl: './navbar-left.component.html',
  styleUrls: ['./navbar-left.component.scss']
})
export class NavbarLeftComponent {
  menuExpanded = false;
  /*isError: Observable<boolean>;*/
  public urlBack: string = environment.portalUrl;
  public supportUrl: string = environment.supportUrl;

}
