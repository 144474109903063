import {Component, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {BsModalService} from 'ngx-bootstrap/modal';
import {InvoiceService} from "../services/invoice.service";
import {InvoiceResults} from "../interfaces/InvoiceResults.interface";
import {BehaviorSubject, Observable} from "rxjs";
import {InvoiceRequest} from "../interfaces/InvoiceRequest.interface";


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  // searchDetailsForm: FormGroup;
  searchForm!: FormGroup;
  closeInformationBanner: boolean = false;
  invoicesRespList: any;
  pagination: any;
  initialRecordInPage?: number;
  finalRecordInPage?: number;
  isNextButtonEnabled?: boolean;
  isPreviousButtonEnabled?: boolean;
  sortDir?: string;
  sortColumn?: string;
  invoiceList: BehaviorSubject<InvoiceResults> = new BehaviorSubject<InvoiceResults>({} as InvoiceResults);
  //selectedBand: string;
  brandSelected: string;
  propertySelected: string;
  propertyMap = new Map();

  readonly FORM_FIELD_VALUES = {
    brandCode: 'brandCode',
    propertyId: 'propertyId',
    agency: 'agency',
    invoiceSupplyDateFrom: 'invoiceSupplyDateFrom',
    invoiceSupplyDateTo: 'invoiceSupplyDateTo',
  };


  brandsList = {};
  propertyList = {};

  constructor(private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private invoiceService: InvoiceService) {

  }

  ngOnInit() {

    this.initForm();
    // this.getFilteredTransactionList();
    this.initializePaginationData();
  }

  private getFilteredTransactionList() {
    //  if (this.searchForm.valid) {
    console.log("Invoice Request and response details : ");
    var modal = this.buildTransactionListRequestPayload();
    this.invoiceService.getInvoices(modal).subscribe(data => {
      console.log("Invoice response details : " + JSON.parse(data.body.results));
    });

  }

  private initForm() {

    this.searchForm = this.formBuilder.group({
      brandCode: [null, Validators.required],
      propertyId: [null],
      agency: [''],
      invoiceSupplyDateFrom: [''],
      invoiceSupplyDateTo: [''],
      invoiceNumber: [''],
      limit: ['10'],
      page: ['1'],
      order: ['desc'],
      sortField: [''],
    });

    this.getBrandsList({'chainCodes': 'HI'});
    this.getPropertiesList({'brand': 'none'});


    this.searchForm.valueChanges.subscribe(() => {
      console.log('Form is dirty: ', this.searchForm.dirty);
      console.log('Form is valid: ', this.isFormValid());
    });

  }

  private checkToken() {
    this.invoiceService.getToken()
      .subscribe((data) => {
        console.log('Triggered token:', data); // Log the data
      }, error => {
        console.error('Error fetching brands list', error);
      });
  }

  private getBrandsList(brand_request: any) {
    this.invoiceService.getBrands(brand_request)
      .subscribe((data) => {
        console.log('Received data:', data); // Log the data
        this.brandsList = JSON.parse(data.body);
      }, error => {
        console.error('Error fetching brands list', error);
      });
  }

  getPropertiesList(property_request) {
    if (property_request && property_request.brand != 'none') {
      this.invoiceService.getProperties(property_request)
        .subscribe((data) => {
          console.log('Received data:', data); // Log the data
          this.propertyList = JSON.parse(data.body); // Access the nested property array
          this.preparePropertyIdAndNameMap();
        }, error => {
          console.error('Error fetching properties list', error);
        });
    }
  }


  public configuration: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false
  };

  onReset(): void {
    this.searchForm?.reset({
      limit: '10',
      page: '1',
      order: 'asc',
    });
    console.log(this.searchForm.controls)
  }

  isFormValid(): boolean {
    if (this.searchForm?.valid) {
      return true;
    }
    return false;
  }

  isResetFilterButtonVisible(): boolean {
    if (this.searchForm?.dirty) {
      return true;
    }
    return false;
  }

  onSubmit(): void {
    if (this.searchForm.valid) {
      this.brandSelected = this.searchForm.value['brandCode']?.brandName
      this.propertySelected = this.searchForm.value['propertyId']?.propertyName
      console.log('on submit action :' + this.searchForm.value);
      this.goToFirstPage()
    }
  }

  onInformationCloseClick(): void {
    this.closeInformationBanner = true;
  }

  changeLimitPerPage() {
    this.goToFirstPage();
  }

  private initializePaginationData() {
    let pageSize: string = '10';
    let currentPage: string = '1';

    if (this.pagination?.itemsPerPage) {
      pageSize = this.pagination?.itemsPerPage;
      currentPage = this.pagination?.currentPage;
    }
    this.initialRecordInPage = Number(Number(Number(currentPage) - 1) * Number(pageSize) + 1);
    this.isPreviousButtonEnabled = false;
    this.searchForm.patchValue({
      limit: pageSize,
      page: currentPage,
    });

    this.commonParametersConfigureForDifferentActionsForPagination();
  }

  private commonParametersConfigureForDifferentActionsForPagination() {
    this.invoiceList.subscribe(invoiceDetails => {
      this.finalRecordInPage = Number(invoiceDetails?.invoices?.length) + (Number(this.searchForm?.controls['limit']?.value) * (Number(this.searchForm?.controls['page']?.value) - 1));
      this.initialRecordInPage = (Number(this.searchForm?.controls['limit']?.value) * (Number(this.searchForm?.controls['page']?.value) - 1)) + 1;
      let limitOfRecords = Number(this.searchForm.controls['limit'].value);
      var finalPage = Number(invoiceDetails?.invoices?.length) < Number(this.searchForm.controls['limit'].value) ? ((Number(this.searchForm?.controls['page']?.value) - 1) + Math.ceil(Number(invoiceDetails.invoices.length) / limitOfRecords)) : Math.ceil(Number(invoiceDetails?.pagination?.totalNumberOfInvoicesForSearch) / limitOfRecords);
      this.isNextButtonEnabled = Number(this.searchForm.controls['page'].value) >= finalPage ? false : true;
      this.isPreviousButtonEnabled = Number(this.searchForm.controls['page'].value) <= 1 ? false : true;
    });


  }

  private resetInlineFormFlags() {
    this.searchForm.markAsPristine();
    this.searchForm.markAsUntouched();
  }

  goToFirstPage() {
    this.searchForm.patchValue({page: '1'});
    var modal = this.buildTransactionListRequestPayload();
    this.findTransactions(modal, false);
    // this.resetInlineFormFlags();
  }


  private onPreviousClickFindTransactions() {
    let currentPage = Number(this.searchForm.controls['page'].value);
    var previousPage = Number(currentPage - 1);
    this.searchForm.patchValue({page: previousPage?.toString()});
    var modal = this.buildTransactionListRequestPayload();

    this.findTransactions(modal, false);
    // this.resetInlineFormFlags();
  }

  findTransactions(payload: InvoiceRequest, sort: boolean): void {
    console.log('triggered submit');
    let obs = this.invoiceService.getInvoices(payload);

    obs.subscribe((list) => {
      const results = this.parseInvoiceListResponse(list);
     // console.log('results : ', results.invoices);
// Iterate over the invoices and set the propertyName using the hotel value
      results.invoices.forEach((invoice) => {
        const propertyName = this.propertyMap.get(invoice.hotel);
        if (propertyName) {
          invoice.propertyName = propertyName;
        }
      });
     // console.log(results)
      this.invoiceList.next(results);
      //    this.invoicesRespList = results.invoices;
    });

  }


  private parseInvoiceListResponse(list) {
    const parsedResponse = JSON.parse(list.body);
    const results = parsedResponse.results;
    let modalInvoiceResults = {
      invoices: results,
      pagination: {
        'itemsPerPage': parsedResponse.itemsPerPage,
        'totalNumberOfInvoicesForSearch': parsedResponse.totalNumberOfInvoicesForSearch,
        'currentPage': parsedResponse.currentPage,
        'totalNumberOfPagesForCriteria': parsedResponse.totalNumberOfPagesForCriteria
      }

    }
    return modalInvoiceResults;
  }

  private preparePropertyIdAndNameMap() {
    Object.entries(this.propertyList).forEach(([key, value]) => {
      this.propertyMap.set(value['propertyId'], value['propertyName']);
    });
  }

  private buildTransactionListRequestPayload() {
    var modal = {} as InvoiceRequest;
    modal.page = this.searchForm.value['page'];
    modal.limit = this.searchForm.value['limit'];
    modal.order = this.searchForm.value['order'];
    modal.sortField = this.searchForm.value['sortField'];
    modal.brand = this.searchForm.value['brandCode']?.brand;
    modal.propertyId = this.searchForm.value['propertyId']?.propertyId ? [this.searchForm.value['propertyId']?.propertyId] : null;
    modal.agency = this.searchForm.value['agency'];
    modal.invoiceSupplyDateTo = this.searchForm.value['invoiceSupplyDateTo'];
    modal.invoiceSupplyDateFrom = this.searchForm.value['invoiceSupplyDateFrom'];
    modal.invoiceNumber = this.searchForm.value['invoiceNumber'];
    // To-DO - hardcoded the changes for now
    modal.chain = 'HI';

    modal.offset = (
      (Number(modal.page) - 1) * Number(modal.limit)
    )?.toString();
    // if (modal.sortField == '') delete modal.sortField;

    console.log('Request while building' + JSON.stringify(modal));

    return modal;
  }

  get invoices(): Observable<InvoiceResults> {
    return this.invoiceList.asObservable();
  }

  onNextClick(event: Event) {
    event.preventDefault();
    this.onNextClickFindTransactions();
  }

  private onNextClickFindTransactions() {
    let currentPage = Number(this.searchForm.controls['page'].value);
    var nextPage = currentPage + 1;
    this.searchForm.patchValue({page: nextPage?.toString()});
    var modal = this.buildTransactionListRequestPayload();
    this.findTransactions(modal, false);
    //  this.resetInlineFormFlags();
  }

  downloadPdf(invoice_details: any): void {
    console.log('Download pDF initiated')
    let obs = this.invoiceService.downloadPdf(invoice_details);
    obs.subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = invoice_details.invoiceNumber + '.' + invoice_details.agencies + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => {
      console.error('Download error:', error);
    });
  }

  compareBrandFn = (a: any, b: any) => a.brandCode === b.brandCode;
  comparePropertyFn = (a: any, b: any) => a.propertyId === b.propertyId;

  customBrandSearch(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.brandName.toLocaleLowerCase().indexOf(term) > -1 ||
      item.brand.toLocaleLowerCase().indexOf(term) > -1
    );
  }


  customSearch(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.propertyName.toLocaleLowerCase().indexOf(term) > -1 ||
      item.propertyId.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  getAssociatedPropList() {
    console.log('selected Brand value is :', this.searchForm.controls['brandCode'].value);
    this.resetPropertyOnBrandChange();
    this.getPropertiesList(this.searchForm.controls['brandCode'].value);
  }


  private resetPropertyOnBrandChange() {
    this.searchForm.patchValue({propertyId: null});
  }

  onPreviousClick(event: Event) {
    event.preventDefault();
    this.onPreviousClickFindTransactions();
  }

}
