<div *ngIf="isLoading$ | async">
  <div class="spinner-overlay">
    <div class="spinner-border text-primary" role="status">{{ title }}</div>
    <div class="spinner-body">
      <h2 class="spinner-message">{{ message }}</h2>
      <a *ngIf="dismissible" [routerLink]="" (click)="onCancelClick()"
      >Cancel</a
      >
    </div>
  </div>

  <div class="spinner-overlay-backdrop show"></div>
</div>
