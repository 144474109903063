import {Component, OnDestroy, OnInit} from '@angular/core';
import {InvoiceService} from "../services/invoice.service";
import {switchMap, timer} from "rxjs";

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent implements OnInit {

  constructor(private invoiceService: InvoiceService) {
  }

  userId: string;

  ngOnInit(): void {
    this.initializeData();
  }


  private initializeData() {
    timer(1500).pipe(
      // switchMap(() => this.invoiceService.getToken())
    ).subscribe((data) => {
      this.userId = localStorage.getItem('id');
    });
  }
}
