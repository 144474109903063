import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchFormComponent} from "./search-form/search-form.component";
import {SecureResolver} from "./shared/resolvers/secure.resolver";
import {DefaultActionsComponent} from "./shared/default-action/default-actions/default-actions.component";
import {ErrorActionComponent} from "./shared/error-action/error-action/error-action.component";

const routes: Routes = [
  {
    path: '',
    component: SearchFormComponent,
    resolve: { token: SecureResolver }
  },
  {
    path: 'default-actions',
    component: DefaultActionsComponent
  },
  {
    path: 'error',
    component: ErrorActionComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}





