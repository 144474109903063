<ng-template #template>
  <div class="modal-content">
    <div class="modal-header modal-section-no-border">
      <button type="button" (click)="stay()" class="close modal-section-no-border" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body w-100 text-center">
      <h2><strong>{{this.warningHeaderMessage}}</strong></h2>
      <h4>{{this.warningBodyMessage}}</h4>
      <h2><strong>{{this.idleStateCounter}}</strong></h2>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="logout()" class="btn btn-secondary" data-dismiss="modal">Logout Now</button>
    <button type="button" (click)="stay()" class="btn btn-primary">Stay Connected</button>
  </div>
</ng-template>
