<div class="container-navbar-top">
  <div class="row">
    <div class="col py-3">
      <h1 class="nav-title">VAT Invoice Search</h1>
    </div>
    <div class="col py-2 text-center">
      <a href="/" class="nav-logo-link" >
        <img src="assets/logo/normal_logo_u13.png" alt="Onyx CenterSource" class="img-fluid nav-logo" />
      </a>
    </div>
    <div class="col py-3 text-right text-nowrap">
      <!--<a href="#" class="nav-help"  *ngIf="!(isError|async)"><i class="far fa-lg fa-question-circle"></i></a>-->

      <div class="dropdown nav-dropdown">
        <button class="btn btn-light dropdown-toggle" type="button" id="topNavbarDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{userId}}
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="topNavbarDropdownMenuButton">
          <a class="dropdown-item" href="https://www.onyxcentersource.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a class="dropdown-item" href="/default-actions?action=logout">Logout</a>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="d-flex align-self-center">
      <img src="assets/logo/normal_logo_u13.png" alt="Onyx" class="img-fluid nav-logo"/>
    <div class=" main-title">
      ValidStay
    </div>
  </div> -->
</div>
