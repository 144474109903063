import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerServiceService {
  private isLoading: BehaviorSubject<boolean>;

  constructor() {
    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  setValue(newValue: boolean): void {
    this.isLoading.next(newValue);
  }

  getValue(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

}
