import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchFormComponent } from './search-form/search-form.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { NavbarTopComponent } from './navbar-top/navbar-top.component';
import { NavbarLeftComponent } from './shared/navbar-left/navbar-left.component';
import {ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { SpinnerModalComponentComponent } from './shared/spinner-modal-component/spinner-modal-component.component';
import {NgSelectModule} from "@ng-select/ng-select"; // Import HttpClientModule
//import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import {SelfBillingInvoiceInterceptorInterceptor} from "./self-billing-invoice-interceptor.interceptor";
import {SecureResolver} from "./shared/resolvers/secure.resolver";
import { DefaultActionsComponent } from './shared/default-action/default-actions/default-actions.component';
import { ErrorActionComponent } from './shared/error-action/error-action/error-action.component'; // Update this path as necessary
import {SessionTimeoutComponent} from './shared/session-timeout/session-timeout.component';
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";

@NgModule({
  declarations: [
    AppComponent,
    SearchFormComponent,
    NavbarTopComponent,
    NavbarLeftComponent,
    SpinnerModalComponentComponent,
    DefaultActionsComponent,
    ErrorActionComponent,
    SessionTimeoutComponent

  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BsDatepickerModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        NgSelectModule,
      NgIdleKeepaliveModule.forRoot()

    ],
  providers: [  BsModalService, {
    provide: HTTP_INTERCEPTORS,
    useClass: SelfBillingInvoiceInterceptorInterceptor,
    multi: true
  },
  SecureResolver],
  bootstrap: [AppComponent]
})
export class AppModule { }
