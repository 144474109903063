import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "@app-env/environment";

@Component({
  selector: 'app-default-actions',
  templateUrl: './default-actions.component.html',
  styleUrls: ['./default-actions.component.scss']
})
export class DefaultActionsComponent implements OnInit {

  public urlLogout: string = environment.portalUrl + '/j_spring_security_logout';

  constructor(private router: ActivatedRoute) {
  }

  ngOnInit(): void {
    const action = this.router.snapshot.queryParamMap.get('action');
    if (action === 'logout') {
      localStorage.clear();
      const agent = window.navigator.userAgent.toLowerCase();
      this.logoutBasedOnBrowser(agent);
    }
  }

  private logoutBasedOnBrowser(agent: string) {
    if (agent.indexOf('firefox') > -1) {
      //firefox throwing binding error during redirection - hence waiting till page loads
      setTimeout(() => {
          window.location.href = this.urlLogout;
        },
        500);
    } else {
      window.location.href = this.urlLogout;
    }
  }

}
