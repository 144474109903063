import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from "rxjs";
import { SpinnerServiceService} from "../../services/spinner-service.service";

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal-component.component.html',
  styleUrls: ['./spinner-modal-component.component.scss']
})
export class SpinnerModalComponentComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() dismissible: boolean;

  @Output() onCancel = new EventEmitter();

  isLoading$: Observable<boolean>;

  constructor(private spinnerService: SpinnerServiceService) {
    this.title = '';
    this.message = '';
    this.dismissible = false;
  }

  ngOnInit(): void {
    this.isLoading$ = this.spinnerService.getValue();
  }

  onCancelClick() {
    this.onCancel.emit();
  }
}
