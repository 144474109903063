import {Component, Input, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {DocumentInterruptSource, Idle, StorageInterruptSource} from "@ng-idle/core";
//import {PingService} from "../../core/services/ping.service";

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {
  public modalRef?: BsModalRef;
  public idleStateCounter = 'Not started.';
  public timedOut = false;

  @Input() public redirectionUrl = '';
  @Input() public warningHeaderMessage = '';

  @Input() public warningBodyMessage = '';

  @Input() public timeoutWarningMessageDuration = 60;

  @Input() public idleTimeoutDuration = 840;

  @ViewChild('template') templateRef: TemplateRef<any>;


  constructor(private router: Router, private idle: Idle, private modalService: BsModalService) {

  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal(template: TemplateRef<any>) {
    this.modalService.hide();
  }


  // @ts-ignore
  public createCustomInterruptSources = (options) => [
    new DocumentInterruptSource('keydown mousedown touchstart touchmove' +
      'scroll', options),
    new StorageInterruptSource(options),
  ]

  public ngOnInit(): void {

    this.idle.setIdle(Number(this.idleTimeoutDuration));
    this.idle.setTimeout(Number(this.timeoutWarningMessageDuration));
    this.idle.setInterrupts(this.createCustomInterruptSources(null));

    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.closeModal(this.templateRef);
      this.timedOut = true;
      this.router.navigateByUrl(this.redirectionUrl);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.openModal(this.templateRef);
    });

    this.idle.onTimeoutWarning.subscribe((countdown: string) => {
      this.idleStateCounter = '00:' + countdown;
    });

   // /!* setInterval(() => {
   //    this.pingService.renewSession().subscribe(()=> console.log('renewed session'));
   //  }, 1500000);*!/

    this.idle.watch();
  }

  public reset = () => {
    this.idle.watch();
    this.timedOut = false;
  }

  public stay = () => {
    this.closeModal(this.templateRef);
    this.reset();
    location.reload();
  }

  public logout = () => {
    this.closeModal(this.templateRef);
    this.reset();
    console.log("logout triggered")
    this.router.navigateByUrl(this.redirectionUrl);
  }
}
