import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {environment} from '@app-env/environment';
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import {SecurityUser} from "../models/security-user.model";


@Injectable({
  providedIn: 'root'
})
export class InvoiceService  implements OnInit {
  private dataUrl = 'invoiceDetails'; // Path to the mock data file
  private brandDataUrl = 'brands/byChainCode'; // Path to the mock data file
  private propertyDataUrl = 'properties/byBrandCode'; // Path to the mock data file
  private pdfDownloadUrl = 'downloadFile';
  private tokenUrl = 'token';
  private token: string = null;

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              ) {

  }


  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('Authorization');
    this.fetchTokenFromUrl();
  }

  private fetchTokenFromUrl() {
    this.route.queryParams.subscribe(params => {
      console.log(params); // {order: "popular"}
       this.token = params['Authorization'];
      console.log(this.token); // popular
    });
  }

  private filterEmptyStrings(obj: any): any {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== "" && v !== null));
  }

  getInvoices(payload: any): Observable<any> {
    // In a real scenario, you might use the payload to build a query or filter the data
    const body = this.filterEmptyStrings(payload);
    const headers = this.getAuthHeaders();
    return this.http.post<any>(environment.serviceUrl + this.dataUrl, body, {headers});
  }


  getAuthHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.token) {
      headers = headers.set('Authorization', this.token);
    }
    return headers;
  }

  getBrands(payload: any): Observable<any> {
    const body = {
      "chainCodes": payload.chainCodes
    };
    const headers = this.getAuthHeaders();
    return this.http.post<any>(environment.serviceUrl + this.brandDataUrl, body , {headers});
  }

  getProperties(payload: any): Observable<any> {

    const body = {
      "brand": payload.brand
    };
    const headers = this.getAuthHeaders();
    return this.http.post<any>(environment.serviceUrl + this.propertyDataUrl, body, {headers});
  }

  getToken(): Observable<any> {
    const body = {
      "brand": "test"
    };
    this.fetchTokenFromUrl();
    const headers = this.getAuthHeaders();
    return this.http.post<any>(environment.serviceUrl + this.tokenUrl, body, {headers}).pipe(
      tap((tokenResponse: any) => {
        localStorage.setItem('id', JSON.parse(tokenResponse.body));
      })
    );
  }

  downloadPdf(invoiceDetails: any) {
    const body = {
      "arc_iata": invoiceDetails.agencies,
      "invoiceNum": '' + invoiceDetails.invoiceNumber,
      //To-DO hardcoded for now.
      "chain": "HI",
      "period_end": invoiceDetails.invoiceSupplyDate.replace(/-/g, '')
    };

    const headers = this.getAuthHeaders();
    return this.http.post(environment.serviceUrl + this.pdfDownloadUrl, body, {
      //To-Do - add security
      headers: {'Accept': 'application/pdf'},
      responseType: 'blob'  // Important
    });
  }

}

