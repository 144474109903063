import { EnvironmentConfiguration } from '@app-core/models';

export const environment: EnvironmentConfiguration = {
  name: 'prod',
  production: true,
  serviceUrl: 'https://selfbillinginvoice-service.app.onyxcentersource.com/',
  requestTimeout: 300000,
  applicationUrl: 'https://validstay.onyxcentersource.com/',
  portalUrl: 'https://portal.onyxcentersource.com/',
  supportUrl: 'https://www.onyxcentersource.com/need-assistance/',
  googleAnalyticsId: 'G-KJTLNVV7J7'
};
