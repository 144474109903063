import {Component} from '@angular/core';
import {environment} from "@app-env/environment";
import {SpinnerServiceService} from "../../../services/spinner-service.service";

@Component({
  selector: 'app-error-action',
  templateUrl: './error-action.component.html',
  styleUrls: ['./error-action.component.scss']
})
export class ErrorActionComponent {
  errorTitle = 'Connection Error';
  errorMessage =
    'An unknown error happened. Please log back in via the Portal.';
  exceptionCode = '';

  public urlLogout: string = environment.portalUrl + '/j_spring_security_logout';


  constructor(private spinnerService: SpinnerServiceService) {
    spinnerService.setValue(false);
  }
}
