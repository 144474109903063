import * as _ from 'lodash';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export class JwtUtil {

  constructor() {
    throw new SyntaxError('Utility class');
  }


  /**
   * Gets expiration time of provided {@link JwtPayload}.
   *
   * @param inputToCheck
   *    {@link JwtPayload} to get its expiration time
   *
   * @return {@link Date} with expiration time of given {@code inputToCheck},
   *         {@code null} if {@code inputToCheck} is {@code undefined}, {@code null} or does not contain {@code exp} property
   */
  static getExpirationDate(inputToCheck?: JwtPayload | null): Date | null;

  /**
   * Gets expiration time of provided JWT token.
   *
   * @param inputToCheck
   *    JWT token to get its expiration time
   *
   * @return {@link Date} with expiration time of given {@code inputToCheck},
   *         {@code null} if {@code inputToCheck} is {@code undefined}, {@code null} or does not contain {@code exp} property
   */
  static getExpirationDate(inputToCheck?: string | null): Date | null;

  /**
   * Gets expiration time of provided {@link JwtPayload} or JWT token.
   *
   * @param inputToCheck
   *    {@link JwtPayload} or JWT token to get its expiration time
   *
   * @return {@link Date} with expiration time of given {@code inputToCheck},
   *         {@code null} if {@code inputToCheck} is {@code undefined}, {@code null} or does not contain {@code exp} property
   */
  static getExpirationDate(inputToCheck?: JwtPayload | string | null): Date | null {
    if (_.isNil(inputToCheck)) {
      return null;
    }
    const payloadToCheck =
      'string' === typeof inputToCheck
        ? this.getPayload(inputToCheck)
        : <JwtPayload>inputToCheck;

    try {
      return _.isNil(payloadToCheck?.exp)
        ? null
        : new Date(
          payloadToCheck!.exp
        );
    } catch (e) {
      return null;
    }
  }


  /**
   * Returns the JWT token with the latest expiration time of provided ones.
   *
   * @param firstToken
   *    First JWT token to verify
   * @param secondToken
   *    Second JWT token to verify
   *
   * @return the token with the latest expiration time of provided ones if, at least one, is a JWT valid token,
   *         {@code null} otherwise.
   */
  static getTokenWithLatestExpiration(firstToken?: string | null,
                                      secondToken?: string | null): string | null {
    const expFirstToken = this.getExpirationDate(firstToken);
    const expSecondToken = this.getExpirationDate(secondToken);

    if (_.isNil(expFirstToken)) {
      return _.isNil(expSecondToken)
        ? null
        : secondToken!;
    }
    if (_.isNil(expSecondToken)) {
      return _.isNil(expFirstToken)
        ? null
        : firstToken!;
    }
    return expFirstToken! > expSecondToken!
      ? firstToken!
      : secondToken!;
  }


  /**
   * Returns the payload of the given JWT token.
   *
   * @param tokenToCheck
   *    JWT token to get payload
   *
   * @return {@link JwtPayload} is given {@code tokenToCheck} is a valid JWT token,
   *         {@code null} otherwise
   */
  static getPayload(tokenToCheck?: string | null): JwtPayload | null {
    if (_.isNil(tokenToCheck)) {
      return null;
    }
    try {
      return jwtDecode<JwtPayload>(tokenToCheck);
    } catch (e) {
      return null;
    }
  }

}
