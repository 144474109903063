<div class="container-navbar-left" [ngClass]="{'expanded': menuExpanded}">
  <div class="nav-header">
    <a [routerLink]="" (click)="menuExpanded = true" class="nav-expand" title="Expand">
      <i class="far fa-bars"></i>
    </a>

    <span class="nav-title">MENU</span>
    <a [routerLink]="" (click)="menuExpanded = false" class="nav-close" title="Collapse">
      <i class="far fa-times"></i>
    </a>
  </div>

  <div class="nav-body">
    <ul class="nav-item-list">
      <li class="nav-item active">
        <a [routerLink]="'/home'" title="Home">
          <span class="nav-item-indicator">
            <i class="fa-home-lg-alt far"></i>
          </span>
          <span class="nav-item-label">
            Home
          </span>
        </a>
      </li>

      <li class="nav-item">
        <a href={{supportUrl}} title="Support" target="_blank" rel="noopener noreferrer">
          <span class="nav-item-indicator">
            <i class="far fa-fw fa-bookmark"></i>
          </span>
          <span class="nav-item-label">
            Support
          </span>
        </a>
      </li>


    </ul>
  </div>

  <div class="nav-footer">
    <ul class="nav-item-list">
      <li class="nav-item">
        <a href={{urlBack}} title="Switch Application (Launch the Portal)">
          <span class="nav-item-indicator">
            <i class="far fa-exchange"></i>
          </span>
          <span class="nav-item-label">
            Switch Application
            <span class="nav-item-caption">(Launch the Portal)</span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="container-navbar-left-backdrop"></div>
